import PropTypes from 'prop-types'

import Button from '../atoms/Button'
import Modal from '../organisms/Modal'

const RequestAlreadyPending = ({ isOpen, handleClose }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} variant='purple'>
      <h3>Hmm, request already pending</h3>
      <p>
        It looks like there is already a pending request. Check your MetaMask
        wallet and accept or reject it!
      </p>
      <Button
        variant='secondary'
        colorscheme='white'
        label='Cancel'
        handleClick={handleClose}
      />
    </Modal>
  )
}
RequestAlreadyPending.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
}

export default RequestAlreadyPending
