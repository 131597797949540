import { motion } from 'framer-motion'
import styled from 'styled-components'

import { buttonStyles } from '../../../styles/Shared'

// 10/10 Will recieve pitchforks for this
// And I deserve it. I do. I absolutely do.
// ^ leaving this as a sign of hope
// it's ok to cause max(pain) on the *first* iteration
export const AnchorStyled = styled(motion.a)`
  border-radius: 25px;
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: self-end;
  justify-content: center;
  gap: 0.5em;
  border-radius: 50px;
  vertical-align: top;
  font-size: 1rem;
  padding: 0.6em 1em 0.65em;
  line-height: 1.2;
  user-select: none;
  &:hover {
    text-decoration: none;
  }
  img {
    user-select: none;
    height: 1rem;
    width: 1rem;
  }
  ${buttonStyles}
`

export const ButtonStyled = styled(motion.button)`
  background-color: transparent;
  border: none;
  user-select: none;
  justify-content: center;
  ${buttonStyles}
`
