import PropTypes from 'prop-types'

import Button from '../atoms/Button'
import Modal from '../organisms/Modal'

const UserRejectedRequest = ({ isOpen, handleClose }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} variant='red'>
      <h3>Hmm, it looks like you rejected the request.</h3>
      <p>
        In order to proceed, you need to accept to sign the request through your
        MetaMask wallet.
      </p>
      <Button
        variant='secondary'
        colorscheme='white'
        label='Cancel'
        handleClick={handleClose}
      />
    </Modal>
  )
}
UserRejectedRequest.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
}

export default UserRejectedRequest
