import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import {
  useAccount,
  useConnect,
  useEnsAvatar,
  useEnsName,
  useNetwork
} from 'wagmi'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'

import { Container } from './AuthStatus.style'

import logo from '../../../assets/brand/rubiks-transparent.svg'
import useNotification, {
  NotificationTypes
} from '../../../hooks/useNotification'
import useSession from '../../../hooks/useSession'
import Account from '../../atoms/Account'
import Button from '../../atoms/Button'

const chainId = Number(process.env.REACT_APP_CHAIN_ID)

function Wrapper({ children, connected = false }) {
  const navigate = useNavigate()
  return (
    <Container connected={connected}>
      <img
        src={logo}
        name='auth-status-logo'
        onClick={() => navigate('/')}
        alt='AuthStatus'
      />
      {children}
    </Container>
  )
}

Wrapper.propTypes = {
  connected: PropTypes.bool,
  children: PropTypes.node.isRequired
}

const AuthStatus = () => {
  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()
  const { connect } = useConnect({
    // Should we use MetaMaskConnector or InjectedConnector?
    connector: new MetaMaskConnector(),
    onError: (error) => {
      // Notify user in case of errors
      if (error.code === 4001) {
        notify(NotificationTypes.UserRejectedRequest)
      }
      if (error.code === -32002) {
        notify(NotificationTypes.RequestAlreadyPending)
      }
    }
  })
  const { session, isConnecting, signin, signout } = useSession()
  const { data: ensNameData } = useEnsName({ address })
  const { data: ensAvatarData } = useEnsAvatar({ addressOrName: address })
  const { notify } = useNotification()
  // There is a infinite loop between this component and NotificationProvider: if need to track
  // when I notify missing MM in order to break the loop
  const [notified, setNotified] = useState(false)
  const navigate = useNavigate()

  // keep an eye on chain switches
  useEffect(() => {
    if (chain && chain.id !== chainId) {
      notify(NotificationTypes.ChangeNetwork)
    }
  }, [chain])

  if (!window.ethereum) {
    if (!notified) {
      setNotified(true)
      notify(NotificationTypes.MetaMaskRequired)
    }
    return (
      <Wrapper>
        <Button
          role='link'
          variant='primary'
          colorscheme='purple'
          label='Install MetaMask'
          icon='metamask'
          url='https://metamask.io/'
        />
      </Wrapper>
    )
  }

  if (!isConnected) {
    return (
      <Wrapper>
        <Button
          role='button'
          variant='primary'
          colorscheme='purple'
          label='Connect with MetaMask'
          icon='metamask'
          handleClick={connect}
        />
      </Wrapper>
    )
  }

  if (!session?.address) {
    return (
      <Wrapper connected>
        <Button
          role='button'
          variant='primary'
          colorscheme='purple'
          label='Sign in with Ethereum'
          handleClick={signin}
          isDisabled={isConnecting}
        />
      </Wrapper>
    )
  }

  // Passing in user here because they may have their ENS and avatar
  // TODO: Figure out how the sign out button should look-- should it be attached to the account? Or exist next to it?
  // Also how communicate that signing out !== disconnecting?
  return (
    <Wrapper>
      <Account
        address={address}
        ens={ensNameData}
        avatar={ensAvatarData}
        style={{ padding: '10px 10px 12px 10px' }}
      />
      <Button
        role='button'
        variant='primary'
        colorscheme='purple'
        label='Sign out'
        name='signout'
        handleClick={() => {
          signout()
          navigate('/', { replace: true })
        }}
      />
    </Wrapper>
  )
}

export default AuthStatus
