import { cloneElement, createContext, useContext, useMemo } from 'react'

import PropTypes from 'prop-types'
import { useImmer } from 'use-immer'

import * as MODALS from '../components/modals'
import { generateUUID } from '../utils'

export const NotificationTypes = {
  MetaMaskRequired: 'MISSING_METAMASK',
  ChangeNetwork: 'CHANGE_NETWORK',
  TransactionInitiated: 'TRANSACTION_INITIATED',
  TransactionPending: 'TRANSACTION_PENDING',
  TransactionSucceeded: 'TRANSACTION_SUCCEEDED',
  TransactionFailed: 'TRANSACTION_FAILED',
  UserRejectedRequest: 'USER_REJECTED_REQUEST',
  RequestAlreadyPending: 'REQUEST_ALREADY_PENDING',
  GenericError: 'GENERIC_ERROR',
  AuthenticationFailed: 'AUTHENTICATION_FAILED',
  SuggestMobile: 'SUGGEST_MOBILE',
  Whitelist: 'WHITELIST'
}

const NotificationContext = createContext()

export const NotificationProvider = ({ children }) => {
  const [{ modals }, setNotification] = useImmer({
    modals: []
  })

  const appendModal = (id, modal) => {
    setNotification((draft) => {
      draft.modals.push({ id, modal })
    })
  }

  const closeModal = useMemo(
    () => (id) => () => {
      setNotification((draft) => {
        const idx = draft.modals.findIndex((m) => m.id === id)
        if (idx > -1) {
          draft.modals.splice(idx, 1)
        }
      })
    },
    []
  )

  const notify = useMemo(
    () => (type, message, links) => {
      // We need to use `setTimeout` to avoid a rendering of modals during firing component rendering.
      // If we can find a better soultion, we can chnge it.
      setTimeout(() => {
        const id = generateUUID()
        const handleClose = closeModal(id)

        switch (type) {
          case NotificationTypes.MetaMaskRequired:
            appendModal(
              id,
              <MODALS.MetaMaskRequired
                handleClose={handleClose}
                isOpen={true}
              />
            )
            break
          case NotificationTypes.ChangeNetwork:
            appendModal(
              id,
              <MODALS.ChangeNetwork handleClose={handleClose} isOpen={true} />
            )
            break
          case NotificationTypes.UserRejectedRequest:
            appendModal(
              id,
              <MODALS.UserRejectedRequest
                handleClose={handleClose}
                isOpen={true}
              />
            )
            break
          case NotificationTypes.RequestAlreadyPending:
            appendModal(
              id,
              <MODALS.RequestAlreadyPending
                handleClose={handleClose}
                isOpen={true}
              />
            )
            break
          case NotificationTypes.Whitelist:
            appendModal(
              id,
              <MODALS.Whitelist handleClose={handleClose} isOpen={true} />
            )
            break
          case NotificationTypes.AuthenticationFailed:
            appendModal(
              id,
              <MODALS.AuthenticationFailed
                handleClose={handleClose}
                isOpen={true}
              />
            )
            break
          case NotificationTypes.TransactionInitiated:
            appendModal(
              id,
              <MODALS.TransactionInitiated
                handleClose={handleClose}
                isOpen={true}
                message={message}
              />
            )
            break
          case NotificationTypes.TransactionSucceeded:
            appendModal(
              id,
              <MODALS.TransactionSucceeded
                handleClose={handleClose}
                isOpen={true}
                message={message}
                links={links}
              />
            )
            break
          case NotificationTypes.SuggestMobile:
            appendModal(
              id,
              <MODALS.SuggestMobile handleClose={handleClose} isOpen={true} />
            )
            break
          case NotificationTypes.GenericError:
            appendModal(
              id,
              <MODALS.GenericError
                handleClose={handleClose}
                message={message}
                isOpen={true}
              />
            )
            break
        }
      }, 100)
    },
    []
  )

  const currentModal = useMemo(() => {
    if (modals.length) {
      return cloneElement(modals[0].modal, { isOpen: true })
    }
    return null
  }, [modals])

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      {currentModal}
    </NotificationContext.Provider>
  )
}
NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired
}

const useNotification = () => useContext(NotificationContext)
export default useNotification
