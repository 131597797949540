import Icon from '../../atoms/Icon'

export const SOCIAL_LINKS = [
  {
    href: 'https://twitter.com',
    icon: <Icon name='twitter' width={24} height={24} />
  },
  {
    href: 'https://discord.com',
    icon: <Icon name='discord' width={24} height={24} />
  },
  {
    href: 'https://github.com',
    icon: <Icon name='github' width={24} height={24} />
  },
  {
    href: 'https://gnosis.io/',
    icon: <Icon name='blockscout' width={24} height={24} />
  },
  {
    href: 'https://opensea.com',
    icon: <Icon name='opensea' width={24} height={24} />
  }
]
