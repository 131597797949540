import React from 'react'

import PropTypes from 'prop-types'

import { Icon } from './IconButton.style'

import { ICONS } from '../../../styles/IconLibrary'
import { default as SvgIcon } from '../Icon'


// Perhaps add props for sizing
// Sm -> 25px
// Md -> 30px
const IconButton = ({ icon, handleClick, ...props }) => {
  return (
    // Propagate props from parent component. It's usefull for styling
    <Icon onClick={handleClick} {...props}>
      {ICONS[icon] && <SvgIcon name={icon} {...props} />}
    </Icon>
  )
}

export default IconButton

IconButton.propTypes = {
  icon: PropTypes.string,
  handleClick: PropTypes.func
}
