import styled, { css } from 'styled-components'

import { DEFAULT_COLOR_PALETTE } from '../../../styles/ColorPalette'
import { SIZE } from '../../../styles/Size'

const { grayScales, palette } = DEFAULT_COLOR_PALETTE

export const Container = styled.li`
  display: ${({ desktop }) => (desktop ? 'block' : 'none')};

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    border-bottom: 1px solid;
    font-size: 18px;
    line-height: 1.5;
    ${({ disabled }) => {
      if (disabled) {
        return css`
          color: ${grayScales.text.disabled} !important;
          pointer-events: none;
        `
      }
      return css`
        color: ${grayScales.text.primary} !important;
      `
    }}
    ${({ active }) => {
      return css`
        border-bottom-color: ${active ? palette.purple.primary : 'transparent'};
      `
    }}
    img,
    svg {
      height: 24px;
      width: 24px;
    }
    &:hover {
      text-decoration: none;
    }
  }

  @media screen and (max-width: ${SIZE.mobile}) {
    display: block;

    a {
      ${({ active }) => {
        return css`
          color: ${active ? palette.purple.primary : 'inherit'};
          border-bottom-color: transparent;
        `
      }}
    }
  }
`
