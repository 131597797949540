import React from 'react'
// Should we import suspense and lazy from react?

import { Route, Routes } from 'react-router-dom'

import PrivateRoutes from './PrivateRoutes'
import { LoaderWithOverlay as Loader } from './atoms/Loader'
import Page404 from './pages/Page404'
import Layout from './templates/Layout'

const Landing = React.lazy(() => import('./pages/Landing'))
const Dashboard = React.lazy(() => import('./pages/Dashboard'))
const Profile = React.lazy(() => import('./pages/Profile'))
const Course = React.lazy(() => import('./pages/Course'))
const Lesson = React.lazy(() => import('./pages/Lesson'))
const Workspace = React.lazy(() => import('./pages/Workspace'))
const BootcampNFT = React.lazy(() => import('./pages/BootcampNFT'))
const CourseInterface = React.lazy(() => import('./pages/CourseInterface'))
const GitPOAP = React.lazy(() => import('./pages/GitPOAP'))
const ModuleNFTs = React.lazy(() => import('./pages/ModuleNFTs'))
const GovernContributions = React.lazy(() =>
  import('./pages/GovernContributions')
)
const NobleDiscord = React.lazy(() => import('./pages/NobleDiscord'))

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Layout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<Loader />}>
              <Landing />
            </React.Suspense>
          }
        />
        <Route
          path={'course'}
          element={
            <React.Suspense fallback={<Loader />}>
              <Course />
            </React.Suspense>
          }
        />
        <Route
          path='course-interface'
          element={
            <React.Suspense fallback={<Loader />}>
              <CourseInterface />
            </React.Suspense>
          }
        />
        <Route
          path='bootcamp-nft'
          element={
            <React.Suspense fallback={<Loader />}>
              <BootcampNFT />
            </React.Suspense>
          }
        />
        <Route
          path='gitpoap'
          element={
            <React.Suspense fallback={<Loader />}>
              <GitPOAP />
            </React.Suspense>
          }
        />
        <Route
          path='module-nfts'
          element={
            <React.Suspense fallback={<Loader />}>
              <ModuleNFTs />
            </React.Suspense>
          }
        />
        <Route
          path='govern-contributions'
          element={
            <React.Suspense fallback={<Loader />}>
              <GovernContributions />
            </React.Suspense>
          }
        />
        <Route
          path='noble-discord'
          element={
            <React.Suspense fallback={<Loader />}>
              <NobleDiscord />
            </React.Suspense>
          }
        />
        <Route element={<PrivateRoutes />}>
          <Route
            path='dashboard'
            element={
              <React.Suspense fallback={<Loader />}>
                <Dashboard />
              </React.Suspense>
            }
          />
          <Route
            path={'profile'}
            element={
              <React.Suspense fallback={<Loader />}>
                <Profile />
              </React.Suspense>
            }
          />
          <Route
            path={':moduleId/:lessonId'}
            element={
              <React.Suspense fallback={<Loader />}>
                <Lesson />
              </React.Suspense>
            }
          />
          <Route
            path={'workspace'}
            element={
              <React.Suspense fallback={<Loader />}>
                <Workspace />
              </React.Suspense>
            }
          />
        </Route>
        <Route path={'*'} element={<Page404 />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
