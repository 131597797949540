export default [
  // {
  //   questionId: 'q1',
  //   id: 'introduction-q1-A',
  //   description: "You're going to do great"
  // },
  // {
  //   questionId: 'q1',
  //   id: 'introduction-q1-B',
  //   description: 'We believe in you!'
  // },
  {
    questionId: 'q1',
    id: 'q1-B',
    description:
      'This is an example of a private blockchain. More specifically, this is known as a consortium.'
  },
  {
    questionId: 'q2',
    id: 'q2-A',
    description: 'Atomicity is the A in ACID transaction properties'
  },
  {
    questionId: 'q2',
    id: 'q2-B',
    description: 'Durability is the D in ACID transaction properties'
  },
  {
    questionId: 'q2',
    id: 'q2-C',
    description: 'Consistency is the C in ACID transaction properties'
  },
  {
    questionId: 'q2',
    id: 'fundamentals-q2-E',
    description: 'In a distribuited ledger there is no central authority'
  },
  {
    questionId: 'q3',
    id: 'q3-A',
    description:
      'In a distributed ledger, the network state is maintained by all network participants'
  },
  {
    questionId: 'q4',
    id: 'q4-B',
    description:
      'Consensus for blockchain is a procedure in which the peers of a Blockchain network reach agreement about the present state of the data in the network. Through this, consensus algorithms establish reliability and trust in the Blockchain network.'
  }
]

// const answers = questions
//   .flatMap((q) => q.answers.map((a) => ({ ...a, questionId: q.id })))
//   .map((answer) => {
//     const correct = correctAnswers.find((c) => c.id === answer.id)
//     return { ...answer, ...correct, correct: Boolean(correct) }
//   })

// export default answers
