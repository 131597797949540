import { createContext, useContext, useEffect, useMemo } from 'react'

import PropTypes from 'prop-types'
import { SiweMessage } from 'siwe'
import { useImmer } from 'use-immer'
import { useAccount, useNetwork, useSignMessage } from 'wagmi'

import useNotification, { NotificationTypes } from './useNotification'

import * as SessionAPI from '../api/session'

const SessionContext = createContext()

export const SessionProvider = ({ children }) => {
  const [state, setState] = useImmer({
    session: null,
    isConnecting: false
  })
  const { notify } = useNotification()
  const { signMessageAsync } = useSignMessage({
    onError: (error) => {
      if (error.code === 4001) {
        notify(NotificationTypes.UserRejectedRequest)
      }
      if (error.code === -32002) {
        notify(NotificationTypes.RequestAlreadyPending)
      }
    }
  })
  const { chain } = useNetwork()
  const { address } = useAccount()

  // Verify if user is currently logged in
  useEffect(() => {
    async function checkSession() {
      try {
        const session = await SessionAPI.checkSession()
        setState((draft) => {
          draft.session = session
        })
      } catch (error) {
        console.error(error)
      }
    }
    checkSession()
  }, [chain?.id, address])

  const signin = useMemo(
    () => async () => {
      // check for required chain id
      if (chain?.id !== parseInt(process.env.REACT_APP_CHAIN_ID, 10)) {
        // Notify user to change to the right network
        notify(NotificationTypes.ChangeNetwork)
        return
      }
      setState((draft) => {
        draft.isConnecting = true
      })
      try {
        const message = new SiweMessage({
          domain: window.location.host,
          address: address,
          statement: 'Sign in with Ethereum to the app.',
          uri: window.location.origin,
          version: '1',
          chainId: chain?.id,
          nonce: await SessionAPI.getNonce()
        })
        const signature = await signMessageAsync({
          message: message.prepareMessage()
        })
        const session = await SessionAPI.signin(message, signature)
        setState((draft) => {
          draft.session = session
          draft.isConnecting = false
        })
      } catch (error) {
        console.error(`Failed to sign in: ${error.message}`, error)
        const { status = 400 } = error.response || {}
        const message =
          status >= 400 && status < 500
            ? NotificationTypes.Whitelist
            : NotificationTypes.AuthenticationFailed
        notify(message)
        setState((draft) => {
          draft.session = null
          draft.isConnecting = false
        })
      }
    },
    [chain?.id, address]
  )

  const signout = useMemo(
    () => async () => {
      await SessionAPI.signout()
      setState((draft) => {
        draft.session = null
      })
    },
    []
  )

  return (
    <SessionContext.Provider
      value={{
        ...state,
        signin,
        signout
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}
SessionProvider.propTypes = {
  children: PropTypes.node.isRequired
}

const useSession = () => useContext(SessionContext)
export default useSession
