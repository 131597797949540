export default [
  // {
  //   lessonId: 'the-first-quiz',
  //   questionId: 'q1',
  //   id: 'introduction-q1-A',
  //   title: 'Heck yes!'
  // },
  // {
  //   lessonId: 'the-first-quiz',
  //   questionId: 'q1',
  //   id: 'introduction-q1-B',
  //   title: 'Of course!'
  // },
  {
    lessonId: 'the-second-quiz',
    questionId: 'q1',
    id: 'q1-A',
    title: 'Public'
  },
  {
    lessonId: 'the-second-quiz',
    questionId: 'q1',
    id: 'q1-B',
    title: 'Private'
  },
  {
    lessonId: 'the-second-quiz',
    questionId: 'q2',
    id: 'q2-A',
    title:
      'Transactions should be atomic. An entire transaction should process to update the database.'
  },
  {
    lessonId: 'the-second-quiz',
    questionId: 'q2',
    id: 'q2-B',
    title:
      'Transactions should be durable. A database should not revert to a previous state.'
  },
  {
    lessonId: 'the-second-quiz',
    questionId: 'q2',
    id: 'q2-C',
    title:
      'Transactions should be consistent. Ledgers should be updated in a reliable, permitted way.'
  },
  {
    lessonId: 'the-second-quiz',
    questionId: 'q2',
    id: 'q2-D',
    title:
      'Transactions should be conditional. They should only be processed for some users, some of the time.'
  },
  {
    lessonId: 'the-second-quiz',
    questionId: 'q2',
    id: 'q2-E',
    title: 'They do not rely on a central authority to maintain data.'
  },
  {
    lessonId: 'the-second-quiz',
    questionId: 'q3',
    id: 'q3-A',
    title: 'They do not rely on a central authority to maintain data.'
  },
  {
    lessonId: 'the-second-quiz',
    questionId: 'q3',
    id: 'q3-B',
    title: 'They do not require data durability.'
  },
  {
    lessonId: 'the-second-quiz',
    questionId: 'q3',
    id: 'q3-C',
    title: 'They do not require atomicity in transactions.'
  },
  {
    lessonId: 'the-second-quiz',
    questionId: 'q3',
    id: 'q3-D',
    title: 'They allow for faster database transactions.'
  },
  {
    lessonId: 'the-second-quiz',
    questionId: 'q4',
    id: 'q4-A',
    title: 'To allow a database to updated very quickly'
  },
  {
    lessonId: 'the-second-quiz',
    questionId: 'q4',
    id: 'q4-B',
    title: 'Facilitate agreement among participants of a distributed system'
  },
  {
    lessonId: 'the-second-quiz',
    questionId: 'q4',
    id: 'q4-C',
    title: 'To protect user data'
  }
]
