import React from 'react'

import PropTypes from 'prop-types'

import { Anchor } from './LinkButton.style'

import { ICONS } from '../../../styles/IconLibrary'
import Icon from '../Icon'


const LinkButton = ({
  icon,
  label,
  href,
  variant,
  colorscheme = null,
  isDisabled
}) => {
  return (
    <Anchor
      variant={variant}
      href={href}
      colorscheme={colorscheme}
      whileTap={!isDisabled && { opacity: 0.4 }}
      whileHover={!isDisabled && { opacity: 0.7 }}
      target='_blank'
    >
      {ICONS[icon] && <Icon name={icon} />}
      {label}
    </Anchor>
  )
}

export default LinkButton

LinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', null]),
  icon: PropTypes.oneOf(['metamask', 'discord']),
  colorscheme: PropTypes.oneOf([
    'default',
    'red',
    'blue',
    'purple',
    'green',
    'orange',
    'yellow'
  ]),
  isDisabled: PropTypes.bool
}
