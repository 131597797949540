import styled from 'styled-components'

export const Icon = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  img {
    height: 25px;
    width: 25px;
  }
`
