import styled from 'styled-components'

export const AvatarSizes = {
  XS: 16,
  S: 32,
  M: 40,
  L: 64,
  XL: 128,
  XXL: 160
}

export const Wrapper = styled.div`
  border-radius: 50%;
  padding: 2px;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  img {
    user-select: none;
    border-radius: 50%;
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
  }
`
