import React from 'react'

import PropTypes from 'prop-types'

import { Wrapper } from './ExternalLink.style'

export default function ExternalLink({ href, children, ...props }) {
  return (
    <Wrapper {...props} href={href} rel='noopener noreferrer' target='_blank'>
      {children}
    </Wrapper>
  )
}

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}
