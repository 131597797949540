import styled from 'styled-components'

import { SIZE } from '../../../styles/Size'

export const Container = styled.div`
  margin: 64px;
  max-width: 100vw;

  @media screen and (max-width: ${SIZE.mobile}) {
    margin: 16px;
  }
`
