// Need to add Modules

/**
 * @typedef {string} LessonType
 */

/**
 * @enum {LessonType}
 */
export const LESSON_TYPE = {
  TEXT: 'text',
  VIDEO: 'video',
  WORKSPACE: 'workspace',
  QUIZ: 'quiz'
}

/**
 * @typedef {Object} Lesson
 * @property {string} id - Lesson's ID
 * @property {string} title - Lesson's Title
 * @property {string} slug - Slug is used in order to retrieve lesson content from github
 * @property {LessonType} type
 */
/**
 * @typedef {Object} Module
 * @property {string} id - Module's ID
 * @property {string} title - Module's Title
 * @property {string} slug
 * @property {Array.<Lesson>} lessons - Module's lessons
 */
export const MODULES = [
  {
    id: 'introduction',
    title: 'Introduction',
    slug: 'S00-intro',
    lessons: [
      {
        id: 'why-learn-blockchain',
        type: LESSON_TYPE.TEXT,
        title: 'Why Learn Blockchain',
        slug: 'L2-why-learn'
      },
      {
        id: 'tips-for-this-course',
        type: LESSON_TYPE.TEXT,
        title: 'Tips For This Course',
        slug: 'L3-course-tips'
      },
      {
        id: 'technical-requirements',
        type: LESSON_TYPE.TEXT,
        title: 'Technical Requirements',
        slug: 'L4-technical-requirements'
      },
      {
        id: 'communication-tools',
        type: LESSON_TYPE.TEXT,
        title: 'Communication Tools',
        slug: 'L5-communication-tools'
      },
      {
        id: 'keeping-up-with-blockchain-developments',
        type: LESSON_TYPE.TEXT,
        title: 'Keeping up with Blockchain Developments',
        slug: 'L6-keeping-up'
      },
      {
        id: 'for-advanced-students',
        type: LESSON_TYPE.TEXT,
        title: 'For Advanced Students',
        slug: 'L7-advanced-students'
      },
      {
        id: 'whats-exciting-about-blockchain',
        type: LESSON_TYPE.VIDEO,
        slug: '',
        title: 'What’s Exciting about Blockchain',
        url: 'https://drive.google.com/file/d/1Kd9Ngz6yMDVUUm372RUxZqpF2FO_vsOH/preview?usp=sharing'
      },
      {
        id: 'the-first-quiz',
        type: LESSON_TYPE.QUIZ,
        title: 'Quiz!',
        slug: 'quiz',
        questions: [
          {
            id: 'q1',
            title: 'Are you ready to start?',
            multiple: true,
            hint: ''
          }
        ]
      }
    ]
  },
  {
    id: 'fundamentals',
    title: 'Fundamentals',
    slug: 'S01-fundamentals',
    lessons: [
      {
        id: 'a-paper-blockchain',
        type: LESSON_TYPE.VIDEO,
        slug: '',
        title: 'A Paper Blockchain',
        url: 'https://drive.google.com/file/d/1j9xrh1a3Dx1lbd0XnY_mgy67mmQVc5eu/preview?usp=sharing'
      },
      {
        id: 'introduction-fundamentals',
        type: LESSON_TYPE.TEXT,
        slug: 'M0-intro',
        title: 'Introduction to Fundamentals'
      },
      {
        id: 'decentralizing-identity-public-key-cryptography',
        type: LESSON_TYPE.VIDEO,
        title: 'Decentralizing Identity: Public Key Cryptography',
        url: 'https://drive.google.com/file/d/15UW8oTeRw86XwRnn3S0N31WoE6_7vDvg/preview?usp=sharing'
      },
      {
        id: 'additional-material-working-with-private-and-public-keys',
        type: LESSON_TYPE.TEXT,
        title: 'Additional Material: Working with Private and Public Keys',
        slug: 'M1-cryptography/L2-pub-key-crypto-additional'
      },
      {
        id: 'decentralizing-file-integrity-hash-functions',
        type: LESSON_TYPE.VIDEO,
        title: 'Decentralizing File Integrity: Hash Functions',
        url: 'https://drive.google.com/file/d/1ruUhM4fDWYIZF5hyDQs9aa_kPrawUyCM/preview?usp=sharing'
      },
      {
        id: 'additional-material-hash-functions',
        type: LESSON_TYPE.TEXT,
        title: 'Additional Material: Hash Functions',
        slug: 'M1-cryptography/L4-hashing-additional'
      },
      {
        id: 'decentralizing-intent-digital-signatures',
        type: LESSON_TYPE.VIDEO,
        title: 'Decentralizing Intent: Digital Signatures',
        url: 'https://drive.google.com/file/d/1TF3AZS10TF7Jw0x8Rtd62D9bfsNH86g4/preview?usp=sharing'
      },
      {
        id: 'additional-material-digital-signatures',
        type: LESSON_TYPE.TEXT,
        title: 'Additional Material: Digital Signatures',
        slug: 'M1-cryptography/L6-digital-sig-additional'
      },
      {
        id: 'cryptography-fundamentals-playground',
        type: LESSON_TYPE.TEXT,
        title: 'Cryptography Fundamentals Playground',
        slug: 'M1-cryptography/L7-crypto-fundamentals-playground'
      },
      {
        id: 'historical-background-when-asymmetric-cryptography-was-almost-made-illegal',
        type: LESSON_TYPE.TEXT,
        title:
          'Historical Background: When Asymmetric Cryptography Was Almost Made Illegal',
        slug: 'M1-cryptography/L8-crypto-wars-history'
      },
      {
        id: 'video-the-crypto-wars-of-the-1990s',
        type: LESSON_TYPE.VIDEO,
        title: 'Video: The Crypto Wars of the 1990s',
        url: 'https://www.youtube-nocookie.com/embed/JE4yoU6ssi8'
      },
      {
        id: 'decentralizing-coordination-consensus',
        type: LESSON_TYPE.VIDEO,
        title: 'Decentralizing Coordination: Consensus',
        url: 'https://drive.google.com/file/d/1t_ZX-ijg96TzgVRVY1-BHGjPtUmr3x1G/preview?usp=sharing'
      },
      {
        id: 'additional-material-consensus',
        type: LESSON_TYPE.TEXT,
        title: 'Additional Material: Consensus',
        slug: 'M2-consensus/L2-consensus-additional'
      },
      {
        id: 'external-resource-the-secret-lives-of-data-raft-algorithm',
        type: LESSON_TYPE.TEXT,
        title: 'External Resource: The Secret Lives of Data: Raft Algorithm',
        slug: 'M2-consensus/L3-raft'
      },
      {
        id: 'trustless-consensus',
        type: LESSON_TYPE.TEXT,
        title: 'Trustless Consensus',
        slug: 'M2-consensus/L5-trustless-consensus'
      },
      {
        id: 'what-is-a-block',
        type: LESSON_TYPE.VIDEO,
        title: 'What is a Block?',
        url: 'https://drive.google.com/file/d/1TJ0MPTS_pjbrUPyGfu_EEPlIF_DDQO8Z/preview?usp=sharing'
      },
      {
        id: 'a-chain-of-blocks',
        type: LESSON_TYPE.VIDEO,
        title: 'A Chain of Blocks',
        url: 'https://drive.google.com/file/d/1EYcHB79pBIY01-OcVceRfJzPlteai7yS/preview?usp=sharing'
      },
      {
        id: 'proof-of-work-in-ethereum',
        type: LESSON_TYPE.VIDEO,
        title: 'Proof of Work in Ethereum',
        url: 'https://drive.google.com/file/d/13l1MZa9Dg4Bq9N5i8TdcKJX28m6kOXvb/preview?usp=sharing'
      },
      {
        id: 'what-is-a-blockchain-fork',
        type: LESSON_TYPE.VIDEO,
        title: 'What is a Blockchain Fork?',
        url: 'https://drive.google.com/file/d/1P-ap7jZlaaKPtZdaNJb5jmDvgY_Rks1s/preview?usp=sharing'
      },
      {
        id: 'other-blockchain-consensus-mechanisms',
        type: LESSON_TYPE.TEXT,
        title: 'Other Blockchain Consensus Mechanisms',
        slug: 'M2-consensus/L10-alt-consensus'
      },
      {
        id: 'consensus-conclusion',
        type: LESSON_TYPE.TEXT,
        title: 'Consensus Conclusion',
        slug: 'M2-consensus/L11-consensus-conclusion'
      },
      {
        id: 'the-mental-model-for-basic-blockchain-architecture',
        type: LESSON_TYPE.TEXT,
        title: 'The Mental Model for Basic Blockchain Architecture',
        slug: 'M3-ag-blockchain/L1-mental-model'
      },
      {
        id: 'configurations-for-different-blockchain-networks',
        type: LESSON_TYPE.TEXT,
        title: 'Configurations for Different Blockchain Networks',
        slug: 'M3-ag-blockchain/L2-configurations'
      },
      {
        id: 'public-and-private-blockchains',
        type: LESSON_TYPE.VIDEO,
        title: 'Public and Private Blockchains',
        url: 'https://drive.google.com/file/d/1GG5eww3fTMGH2ej_LNY4nXbiMHicsPid/preview?usp=sharing'
      },
      {
        id: 'when-to-use-a-blockchain',
        type: LESSON_TYPE.VIDEO,
        title: 'When To Use a Blockchain?',
        url: 'https://drive.google.com/file/d/1lmIxBmoHOPE0_93z8eDl1ZfiF5Pzer4W/preview?usp=sharing'
      },
      {
        id: 'history-and-development-of-bitcoin',
        type: LESSON_TYPE.TEXT,
        title: 'History and Development of Bitcoin',
        slug: 'M4-bitcoin/L1-history-and-development'
      },
      {
        id: 'what-is-a-crypto-wallet',
        type: LESSON_TYPE.VIDEO,
        title: 'What is a Crypto Wallet?',
        url: 'https://drive.google.com/file/d/1lmIxBmoHOPE0_93z8eDl1ZfiF5Pzer4W/preview?usp=sharing'
      },
      {
        id: 'introduction-to-metamask',
        type: LESSON_TYPE.VIDEO,
        title: 'Introduction to MetaMask',
        url: 'https://www.youtube-nocookie.com/embed/Af_lQ1zUnoM'
      },
      {
        id: 'how-does-metamask-keep-your-wallet-safe',
        type: LESSON_TYPE.TEXT,
        title: 'How Does MetaMask Keep Your Wallet Safe?',
        slug: 'M5-wallets/L3-mm-lavamoat'
      },
      {
        id: 'secure-extensible-javascript-how-metamask-is-locking-in-and-building-out',
        type: LESSON_TYPE.VIDEO,
        title:
          'Secure, Extensible Javascript: How MetaMask is Locking In and Building Out',
        url: 'https://drive.google.com/file/d/1aYZfDc9im9Xjktk0QpK47yabtdhGYdHg/preview?usp=sharing'
      },
      {
        id: 'how-to-stay-safe-using-cryptocurrencies',
        type: LESSON_TYPE.VIDEO,
        title: 'How to Stay Safe Using Cryptocurrencies',
        url: 'https://drive.google.com/file/d/1p7aBaQCFbGVo_EIDoL_XBiWZG32_6rop/preview?usp=sharing'
      },
      {
        id: 'the-second-quiz',
        type: LESSON_TYPE.QUIZ,
        title: 'Quiz!',
        slug: 'quiz',
        questions: [
          {
            id: 'q1',
            title:
              'Imagine a group of 15 financial institutions, each of which operates a node in the blockchain and of which 10 must sign every block in order for the block to be valid. The right to read the blockchain may be public, or restricted to the participants. This is an example of what kind of blockchain?',
            multiple: false
          },
          {
            id: 'q2',
            title:
              'Which of the following are important features of distributed database transactions? Select all that apply',
            multiple: true
          },
          {
            id: 'q3',
            title:
              'How are distributed ledgers different than traditional ledgers? Choose one',
            multiple: false
          },
          {
            id: 'q4',
            title: 'What is the goal of a consensus mechanism? Choose one',
            multiple: false
          }
        ]
      }
    ]
  },
  {
    id: 'eth-basics',
    title: 'Ethereum Basics',
    slug: 'S02-ethereum',
    lessons: [
      {
        id: 'ethereum-background-and-history',
        type: LESSON_TYPE.TEXT,
        title: 'Ethereum Background and History',
        slug: 'M1-background'
      },
      {
        id: 'identity-on-ethereum-externally-owned-accounts-eoas-and-smart-contracts',
        type: LESSON_TYPE.VIDEO,
        title:
          'Identity on Ethereum: Externally Owned Accounts (EOAs) and Smart Contracts',
        url: 'https://drive.google.com/file/d/1e3MDt-MNRUvcXZFKyiUBbpezkTkoX-dX/preview?usp=sharing'
      },
      {
        id: 'generating-ethereum-addresses',
        type: LESSON_TYPE.TEXT,
        title: 'Generating Ethereum Addresses',
        slug: 'M2-accounts/L2-generating-accounts'
      },
      {
        id: 'nodes',
        type: LESSON_TYPE.VIDEO,
        title: 'Nodes',
        url: 'https://drive.google.com/file/d/1NCC8XTiSdteR75SNqQ5Xi7xOaKivIvwG/preview?usp=sharing'
      },
      {
        id: 'transactions',
        type: LESSON_TYPE.VIDEO,
        title: 'Transactions',
        url: 'https://drive.google.com/file/d/1-C13kN9uPNInki_7Ap4A2LJdHlfekheo/preview?usp=sharing'
      },
      {
        id: 'gas-and-fees',
        type: LESSON_TYPE.VIDEO,
        title: 'Gas and Fees',
        url: 'https://drive.google.com/file/d/1b_4VWu5FbZ_AB9oFBqVzk9lR3X7WTIEr/preview?usp=sharing'
      },
      {
        id: 'ethereum-structure',
        type: LESSON_TYPE.VIDEO,
        title: 'Ethereum Structure',
        url: 'https://drive.google.com/file/d/1Lufhe61FVREASe0mA8a3zVwLNLqRTtiB/preview?usp=sharing'
      },
      {
        id: 'ethereum-networks',
        type: LESSON_TYPE.TEXT,
        title: 'Ethereum Networks',
        slug: 'M3-state/L8-networks'
      },
      {
        id: 'sending-transactions',
        type: LESSON_TYPE.TEXT,
        title: 'Sending Transactions',
        slug: 'M3-state/L4-txn-tutorial'
      },
      {
        id: 'merkle-trees',
        type: LESSON_TYPE.VIDEO,
        title: 'Merkle Trees',
        url: 'https://drive.google.com/file/d/1ynq5qUftjS9upyghClXBK9fgRYXNjHEQ/preview?usp=sharing'
      },
      {
        id: 'playing-with-merkle-trees',
        type: LESSON_TYPE.TEXT,
        title: 'Playing with Merkle Trees',
        slug: 'M3-state/L6-playing-with-merkle-trees'
      },
      {
        id: 'eip-1559',
        type: LESSON_TYPE.TEXT,
        title: 'EIP-1559',
        slug: 'M3-state/L7-1559'
      },
      {
        id: 'what-is-an-ethereum-client-running-go-ethereum-geth',
        type: LESSON_TYPE.TEXT,
        title: 'What is an Ethereum Client? Running Go-Ethereum (Geth)',
        slug: 'M4-clients-workshop/L1'
      },
      {
        id: 'install-a-full-besu-node-using-bonsai-storage-on-ubuntu-server',
        type: LESSON_TYPE.TEXT,
        title: 'Install a Full Besu Node using Bonsai Storage on Ubuntu Server',
        slug: 'M6-Installing-besu'
      },
      {
        id: 'history-and-roadmap',
        title: 'History and Roadmap',
        type: LESSON_TYPE.TEXT,
        slug: '../S10-eth2/M1-background'
      },
      {
        id: 'key-terms',
        title: 'Key Terms',
        type: LESSON_TYPE.TEXT,
        slug: '../S10-eth2/M2-key-terms'
      },
      {
        id: 'future-considerations',
        title: 'Future Considerations',
        type: LESSON_TYPE.TEXT,
        slug: '../S10-eth2/M3-future-considerations'
      },
      {
        id: 'the-third-quiz',
        type: LESSON_TYPE.QUIZ,
        title: 'Quiz!',
        slug: 'quiz',
        questions: [
          {
            id: 'q1',
            title:
              'Identify the statement below that describes proof-of-work consensus. Choose one.',
            multiple: false
          },
          {
            id: 'q2',
            title:
              'Identify the statement below that describes proof-of-stake.',
            multiple: false
          },
          {
            id: 'q3',
            title:
              'Identify features of the Ethereum protocol that the Bitcoin protocol was not initially designed with',
            multiple: true
          },
          {
            id: 'q4',
            title: 'Proof-of-work and proof-of-stake are most useful for what?',
            multiple: true
          },
          {
            id: 'q5',
            title:
              'Which of the following describes properties of a smart contract?',
            multiple: true
          }
        ]
      }
    ]
  },
  {
    id: 'smart-contracts',
    title: 'Smart Contracts',
    slug: 'S03-smart-contracts',
    lessons: [
      {
        id: 'smart-contracts-what-are-they-how-do-they-fit-in-our-mental-model',
        type: LESSON_TYPE.TEXT,
        title:
          'Smart Contracts: What Are They? How Do They Fit In Our Mental Model?',
        slug: 'M1-mental-model/L1-mental-model-sc'
      },
      {
        id: 'smart-contracts-and-the-evm',
        type: LESSON_TYPE.VIDEO,
        title: 'Smart Contracts and the EVM',
        url: 'https://drive.google.com/file/d/1v0lkYjsExNgO8rNOMjPmLjWjIcSQRWY_/preview?usp=sharing'
      },
      {
        id: 'introduction-to-remix',
        type: LESSON_TYPE.VIDEO,
        title: 'Introduction to Remix',
        url: 'https://drive.google.com/file/d/1WxqZThbCN67fGht6dG-dIlocZPbuoU8j/preview?usp=sharing'
      },
      {
        id: 'introduction-to-truffle-suite-—-part-1',
        type: LESSON_TYPE.TEXT,
        title: 'Introduction to Truffle Suite — Part 1',
        slug: 'M2-intro-to-truffle/L1-background'
      },
      {
        id: 'introduction-to-truffle-suite-—-part-2',
        type: LESSON_TYPE.TEXT,
        title: 'Introduction to Truffle Suite — Part 2',
        slug: 'M2-intro-to-truffle/L2-intro-tutorial'
      },
      {
        id: 'solidity-background-and-context',
        type: LESSON_TYPE.TEXT,
        title: 'Solidity Background and Context',
        slug: 'M2-solidity/L1-background-context'
      },
      {
        id: 'solidity-data-types-and-variables',
        type: LESSON_TYPE.VIDEO,
        title: 'Solidity Data Types and Variables',
        url: 'https://drive.google.com/file/d/1YEqR0fBa0-4J-ClkwTwtQf1fpzAQKsCu/preview?usp=sharing'
      },
      {
        id: 'solidity-functions',
        type: LESSON_TYPE.VIDEO,
        title: 'Solidity Functions',
        url: 'https://drive.google.com/file/d/1YEgpnyMVFdB6y9g4KsgaVuJteiVtFwx-/preview?usp=sharing'
      },
      {
        id: 'solidity-storage-and-memory',
        type: LESSON_TYPE.VIDEO,
        title: 'Solidity Storage and Memory',
        url: 'https://drive.google.com/file/d/1-aEFJ99HEX83deyVbQJQz-UyMYcfdRAk/preview?usp=sharing'
      },
      {
        id: 'solidity-contract-structure',
        type: LESSON_TYPE.VIDEO,
        title: 'Solidity Contract Structure',
        url: 'https://drive.google.com/file/d/1Foei8mGcskwUgSHGmY54Z0lzoes-STzQ/preview?usp=sharing'
      },
      {
        id: 'smart-contract-abi',
        type: LESSON_TYPE.VIDEO,
        title: 'Smart Contract ABI',
        url: 'https://drive.google.com/file/d/1uoQZdPgkumqIZSnM3Ng5HCnrs6MPozwe/preview?usp=sharing'
      },
      {
        id: 'solidity-events-and-logs',
        type: LESSON_TYPE.TEXT,
        title: 'Solidity Events and Logs',
        slug: 'M2-solidity/L7-events-and-logs'
      },
      {
        id: 'solidity-factory-contracts',
        type: LESSON_TYPE.TEXT,
        title: 'Solidity Factory Contracts',
        slug: 'M2-solidity/L8-factory-contracts'
      },
      {
        id: 'external-solidity-by-example-solidity-by-example.org/',
        type: LESSON_TYPE.TEXT,
        title: '(EXTERNAL) Solidity by Example (solidity-by-example.org/)',
        slug: 'M2-solidity/L9-solidity-by-example'
      },
      {
        id: 'python-in-ethereum-background-and-context',
        type: LESSON_TYPE.TEXT,
        title: 'Python in Ethereum: Background and Context',
        slug: 'M3-python/L1-background-and-context'
      },
      {
        id: 'vyper-overview',
        type: LESSON_TYPE.TEXT,
        title: 'Vyper Overview',
        slug: 'M3-python/L2-vyper-overview'
      },
      {
        id: 'design-patterns-introductory-smart-contracts',
        type: LESSON_TYPE.VIDEO,
        title: 'Design Patterns: Introductory Smart Contracts',
        url: 'https://drive.google.com/file/d/1kO1ZYYgmLzZuZOxTzP7cqHyh0CtVE0zF/preview?usp=sharing'
      },
      {
        id: 'inter-contract-execution-—-part-1',
        type: LESSON_TYPE.VIDEO,
        title: 'Inter-Contract Execution — Part 1',
        url: 'https://drive.google.com/file/d/18uZtOKYGknWoHXuc0OM0oP0jM0nZmNR9/preview?usp=sharing'
      },
      {
        id: 'inter-contract-execution-—-part-2',
        type: LESSON_TYPE.VIDEO,
        title: 'Inter-Contract Execution — Part 2',
        url: 'https://drive.google.com/file/d/19Ev-faVyYwIowOqOxux_o7kPhD5fIeq6/preview?usp=sharing'
      },
      {
        id: 'inheritance-and-interfaces',
        type: LESSON_TYPE.VIDEO,
        title: 'Inheritance and Interfaces',
        url: 'https://drive.google.com/file/d/130oMVEbsVOHWPQx5RF3YXfs7FbsY2XFv/preview?usp=sharing'
      },
      {
        id: 'off-chain-oracles',
        type: LESSON_TYPE.TEXT,
        title: 'Off-Chain Oracles',
        slug: 'M4-design-patterns/L4-oracles'
      },
      {
        id: 'access-control-design-patterns',
        type: LESSON_TYPE.TEXT,
        title: 'Access Control Design Patterns',
        slug: 'M4-design-patterns/L5-access-control'
      },
      {
        id: 'general-finance-and-access-control-multi-sig-tutorial',
        type: LESSON_TYPE.TEXT,
        title: 'General Finance and Access Control: Multi-Sig Tutorial',
        slug: 'M4-design-patterns/L6-general-finance'
      },
      {
        id: 'upgradable-contracts',
        type: LESSON_TYPE.VIDEO,
        title: 'Upgradable Contracts',
        url: 'https://drive.google.com/file/d/1RkkyoeE_E3z5zOFO3xk9sSelYMrdohxM/preview?usp=sharing'
      },
      {
        id: 'additional-material-upgradable-contracts',
        type: LESSON_TYPE.TEXT,
        title: 'Additional Material: Upgradable Contracts',
        slug: 'M4-design-patterns/L8-add-material-upgradable-contracts'
      },
      {
        id: 'optimizing-gas',
        type: LESSON_TYPE.TEXT,
        title: 'Optimizing Gas',
        slug: 'M4-design-patterns/L9-optimizing-gas'
      },
      {
        id: 'smart-contract-system-design',
        type: LESSON_TYPE.VIDEO,
        title: 'Smart Contract System Design',
        url: 'https://drive.google.com/file/d/1qzEts03ZBbTM8mBuV4w_kiMma_gM0pGT/preview?usp=sharing'
      },
      {
        id: 'tutorial-proof-of-existence',
        type: LESSON_TYPE.TEXT,
        title: 'Tutorial: Proof of Existence',
        slug: 'M4-design-patterns/L10-proof-of-existence'
      },
      {
        id: 'smart-contract-best-practices',
        type: LESSON_TYPE.VIDEO,
        title: 'Smart Contract Best Practices',
        url: 'https://drive.google.com/file/d/1aG46h5U3bnhwfj168SZM0aLDWNMHfECg/preview?usp=sharing'
      },
      {
        id: 'solidity-pitfalls-and-attacks',
        type: LESSON_TYPE.TEXT,
        title: 'Solidity Pitfalls and Attacks',
        slug: 'M6-security/L2-solidity-pitfalls-and-attacks'
      },
      {
        id: 'smart-contract-pitfalls-and-attacks',
        type: LESSON_TYPE.TEXT,
        title: 'Smart Contract Pitfalls and Attacks',
        slug: 'M6-security/L2a-sc-pitfalls-and-attacks'
      },
      {
        id: 'smart-contract-safety-checklist',
        type: LESSON_TYPE.VIDEO,
        title: 'Smart Contract Safety Checklist',
        url: 'https://drive.google.com/file/d/19Yfke4TJyctmmpcty7nK9Dvzn20gIa2C/preview?usp=sharing'
      },
      {
        id: 'tx-origin-attack-demo',
        type: LESSON_TYPE.TEXT,
        title: 'tx.origin Attack Demo',
        slug: 'M6-security/L2b-tx-origin-attack'
      },
      {
        id: 'denial-of-service-attack-example',
        type: LESSON_TYPE.TEXT,
        title: 'Denial of Service Attack Example',
        slug: 'M6-security/L2c-dos-attack'
      },
      {
        id: 'reentrancy-attack-example',
        type: LESSON_TYPE.TEXT,
        title: 'Reentrancy Attack Example',
        slug: 'M6-security/L2d-reentrancy-attack'
      },
      {
        id: 'integer-under--/-overflow-example',
        type: LESSON_TYPE.TEXT,
        title: 'Integer Under- / Overflow Example',
        slug: 'M6-security/L2e-int-under-over-attack'
      },
      {
        id: 'security-introduction-to-diligence-mythx-and-scribble',
        type: LESSON_TYPE.TEXT,
        title: 'Security: Introduction to Diligence, MythX and Scribble',
        slug: 'M6-security/L5-intro-to-dili'
      },
      {
        id: 'other-security-options',
        type: LESSON_TYPE.TEXT,
        title: 'Other Security Options',
        slug: 'M6-security/L7-other-security-options'
      },
      {
        id: 'the-fourth-quiz',
        type: LESSON_TYPE.QUIZ,
        title: 'Quiz!',
        slug: 'quiz',
        questions: [
          {
            id: 'q1',
            title:
              'Use this contract for the following questions\n' +
              '\n' +
              'Read the following contract and use it to answer the next several questions. It may be helpful to paste this contract into Remix or your text editor to get syntax highlighting. If you use Remix, you can also investigate the contract execution and state to help you determine the answers to the questions.\n' +
              '\n' +
              '<code>' +
              '// SPDX-License-Identifier: GPL-3.0\n' +
              'pragma solidity >=0.6.0 <0.9.0;\n' +
              '\n' +
              'contract CrowdFunding {\n' +
              '// Defines a new type with two fields.\n' +
              'struct Funder {\n' +
              'address addr;\n' +
              'uint amount;\n' +
              '}\n' +
              '\n' +
              '    struct Campaign {\n' +
              '        address payable beneficiary;\n' +
              '        uint fundingGoal;\n' +
              '        uint numFunders;\n' +
              '        uint amount;\n' +
              '        mapping (uint => Funder) funders;\n' +
              '    }\n' +
              '\n' +
              '    uint numCampaigns;\n' +
              '    mapping (uint => Campaign) campaigns;\n' +
              '\n' +
              '    function newCampaign(address payable beneficiary, uint goal) public returns (uint campaignID) {\n' +
              '        campaignID = numCampaigns++; // campaignID is return variable\n' +
              '        // We cannot use "campaigns[campaignID] = Campaign(beneficiary, goal, 0, 0)"\n' +
              '        // because the RHS creates a memory-struct "Campaign" that contains a mapping.\n' +
              '        Campaign storage c = campaigns[campaignID];\n' +
              '        c.beneficiary = beneficiary;\n' +
              '        c.fundingGoal = goal;\n' +
              '    }\n' +
              '\n' +
              '    function contribute(uint campaignID) public payable {\n' +
              '        Campaign storage c = campaigns[campaignID];\n' +
              '        // Creates a new temporary memory struct, initialised with the given values\n' +
              '        // and copies it over to storage.\n' +
              '        // Note that you can also use Funder(msg.sender, msg.value) to initialise.\n' +
              '        c.funders[c.numFunders++] = Funder({addr: msg.sender, amount: msg.value});\n' +
              '        c.amount += msg.value;\n' +
              '    }\n' +
              '\n' +
              '    function checkGoalReached(uint campaignID) public returns (bool reached) {\n' +
              '        Campaign storage c = campaigns[campaignID];\n' +
              '        if (c.amount < c.fundingGoal)\n' +
              '            return false;\n' +
              '        uint amount = c.amount;\n' +
              '        c.amount = 0;\n' +
              '        c.beneficiary.transfer(amount);\n' +
              '        return true;\n' +
              '    }\n' +
              '}\n' +
              '</code>' +
              '\n' +
              'Assume that CrowdFunding contract has been deployed and 3 new campaigns have been created.\n' +
              '\n' +
              'Reference the ABI of the CrowdFunding contract.\n' +
              '\n' +
              'What is the state mutability of the contribute() function?\n' +
              '\n' +
              'Multiple Choice\n',
            multiple: true
          },
          {
            id: 'q2',
            title:
              'What keywords are used to describe a function that does not modify the contracts’ state?\n' +
              'Select all that apply',
            multiple: true
          },
          {
            id: 'q3',
            title:
              'Use this contract for the following questions\n' +
              '\n' +
              'Read the following contract and use it to answer the next several questions. It may be helpful to paste this contract into Remix or your text editor to get syntax highlighting. If you use Remix, you can also investigate the contract execution and state to help you determine the answers to the questions.\n' +
              '\n' +
              '<code>' +
              '// SPDX-License-Identifier: GPL-3.0\n' +
              'pragma solidity >=0.6.0 <0.9.0;\n' +
              '\n' +
              'contract CrowdFunding {\n' +
              '        // Defines a new type with two fields.\n' +
              '        struct Funder {\n' +
              '                address addr;\n' +
              '                uint amount;\n' +
              '        }\n' +
              '\n' +
              '    struct Campaign {\n' +
              '        address payable beneficiary;\n' +
              '        uint fundingGoal;\n' +
              '        uint numFunders;\n' +
              '        uint amount;\n' +
              '        mapping (uint => Funder) funders;\n' +
              '    }\n' +
              '\n' +
              '    uint numCampaigns;\n' +
              '    mapping (uint => Campaign) campaigns;\n' +
              '\n' +
              '    function newCampaign(address payable beneficiary, uint goal) public returns (uint campaignID) {\n' +
              '        campaignID = numCampaigns++; // campaignID is return variable\n' +
              '        // We cannot use "campaigns[campaignID] = Campaign(beneficiary, goal, 0, 0)"\n' +
              '        // because the RHS creates a memory-struct "Campaign" that contains a mapping.\n' +
              '        Campaign storage c = campaigns[campaignID];\n' +
              '        c.beneficiary = beneficiary;\n' +
              '        c.fundingGoal = goal;\n' +
              '    }\n' +
              '\n' +
              '    function contribute(uint campaignID) public payable {\n' +
              '        Campaign storage c = campaigns[campaignID];\n' +
              '        // Creates a new temporary memory struct, initialised with the given values\n' +
              '        // and copies it over to storage.\n' +
              '        // Note that you can also use Funder(msg.sender, msg.value) to initialise.\n' +
              '        c.funders[c.numFunders++] = Funder({addr: msg.sender, amount: msg.value});\n' +
              '        c.amount += msg.value;\n' +
              '    }\n' +
              '\n' +
              '    function checkGoalReached(uint campaignID) public returns (bool reached) {\n' +
              '        Campaign storage c = campaigns[campaignID];\n' +
              '        if (c.amount < c.fundingGoal)\n' +
              '            return false;\n' +
              '        uint amount = c.amount;\n' +
              '        c.amount = 0;\n' +
              '        c.beneficiary.transfer(amount);\n' +
              '        return true;\n' +
              '    }\n' +
              '}\n' +
              '</code>' +
              '\n' +
              'Assume that CrowdFunding contract has been deployed and 3 new campaigns have been created.\n' +
              '\n' +
              "What's the result of using 'storage' keyword in the 'contribute()' function?",
            multiple: false
          },
          {
            id: 'q4',
            title:
              'Use this contract for the following question:\n' +
              '\n' +
              '\n' +
              'Read the following contract and use it to answer the next several questions. It may be helpful to paste this contract into Remix or your text editor to get syntax highlighting. If you use Remix, you can also investigate the contract execution and state to help you determine the answers to the questions.\n' +
              '\n' +
              '<code>' +
              '// SPDX-License-Identifier: GPL-3.0\n' +
              'pragma solidity >=0.6.0 <0.9.0;\n' +
              '\n' +
              'contract CrowdFunding {\n' +
              '                // Defines a new type with two fields.\n' +
              '                struct Funder {\n' +
              '                        address addr;\n' +
              '                        uint amount;\n' +
              '                }\n' +
              '\n' +
              '    struct Campaign {\n' +
              '        address payable beneficiary;\n' +
              '        uint fundingGoal;\n' +
              '        uint numFunders;\n' +
              '        uint amount;\n' +
              '        mapping (uint => Funder) funders;\n' +
              '    }\n' +
              '\n' +
              '    uint numCampaigns;\n' +
              '    mapping (uint => Campaign) campaigns;\n' +
              '\n' +
              '    function newCampaign(address payable beneficiary, uint goal) public returns (uint campaignID) {\n' +
              '        campaignID = numCampaigns++; // campaignID is return variable\n' +
              '        // We cannot use "campaigns[campaignID] = Campaign(beneficiary, goal, 0, 0)"\n' +
              '        // because the RHS creates a memory-struct "Campaign" that contains a mapping.\n' +
              '        Campaign storage c = campaigns[campaignID];\n' +
              '        c.beneficiary = beneficiary;\n' +
              '        c.fundingGoal = goal;\n' +
              '    }\n' +
              '\n' +
              '    function contribute(uint campaignID) public payable {\n' +
              '        Campaign storage c = campaigns[campaignID];\n' +
              '        // Creates a new temporary memory struct, initialised with the given values\n' +
              '        // and copies it over to storage.\n' +
              '        // Note that you can also use Funder(msg.sender, msg.value) to initialise.\n' +
              '        c.funders[c.numFunders++] = Funder({addr: msg.sender, amount: msg.value});\n' +
              '        c.amount += msg.value;\n' +
              '    }\n' +
              '\n' +
              '    function checkGoalReached(uint campaignID) public returns (bool reached) {\n' +
              '        Campaign storage c = campaigns[campaignID];\n' +
              '        if (c.amount < c.fundingGoal)\n' +
              '            return false;\n' +
              '        uint amount = c.amount;\n' +
              '        c.amount = 0;\n' +
              '        c.beneficiary.transfer(amount);\n' +
              '        return true;\n' +
              '    }\n' +
              '}\n' +
              '</code>' +
              'Assume that CrowdFunding contract has been deployed and 3 new campaigns have been created.\n' +
              '\n' +
              'What will the EVM return upon successful execution of newCampaign()?\n' +
              '\n' +
              'Pick one answer',
            multiple: false
          },
          {
            id: 'q5',
            title:
              'Which of the following are true about events?\n' +
              'Select all that apply',
            multiple: true
          },
          {
            id: 'q6',
            title:
              'Which statements are true about a smart contract’s ABI?\n' +
              'Select all that apply',
            multiple: true
          },
          {
            id: 'q7',
            title:
              'Which of the following variable types are reference types (as opposed to value types)?\n' +
              'Choose all that apply',
            multiple: true
          },
          {
            id: 'q8',
            title:
              'Which of the following are valid ways of declaring function returns?\n' +
              'Select all that apply',
            multiple: true
          }
        ]
      },
      {
        id: 'the-fifth-quiz',
        type: LESSON_TYPE.QUIZ,
        title: 'Quiz!',
        slug: 'quiz',
        questions: [
          {
            id: 'q1',
            title:
              'Derived contracts inherit which of the following from parent contracts (in Solidity)?\n' +
              'Choose all that apply',
            multiple: true
          },
          {
            id: 'q2',
            title:
              'Derived contracts cannot have functions of the same name and type/number of inputs.\n' +
              'Choose one.',
            multiple: false
          },
          {
            id: 'q3',
            title:
              'Select the differences between abstract contracts and interfaces in Solidity.\n' +
              'Select all that apply',
            multiple: true
          },
          {
            id: 'q4',
            title:
              'What are some of the potential benefits of using Solidity libraries?\n' +
              'Select all that apply',
            multiple: true
          },
          {
            id: 'q5',
            title:
              'Which of the following are true of libraries?\n' +
              'Select all that apply',
            multiple: true
          }
        ]
      }
    ]
  },
  {
    id: 'frontend-development',
    title: 'Frontend Development',
    slug: 'S04-developer-tooling',
    lessons: [
      {
        id: 'where-do-users-fit-in-our-mental-model',
        type: LESSON_TYPE.TEXT,
        title: 'Where Do Users Fit In Our Mental Model?',
        slug: 'M1-intro/L1-users-in-mental-model'
      },
      {
        id: 'features-of-decentralized-application-development',
        type: LESSON_TYPE.VIDEO,
        title: 'Features of Decentralized Application Development',
        url: 'https://drive.google.com/file/d/1q_xh2imYE6RKRJzh2A2iYS4-2l0t20iF/preview?usp=sharing'
      },
      {
        id: 'traditional-vs-decentralized-development',
        type: LESSON_TYPE.VIDEO,
        title: 'Traditional vs Decentralized Development',
        url: 'https://drive.google.com/file/d/1mvXcvcS3RNMDTW5X5a2ygLfss_-ykjpT/preview?usp=sharing'
      },
      {
        id: 'agnostic-developer-workflow',
        type: LESSON_TYPE.TEXT,
        title: 'Agnostic Developer Workflow',
        slug: 'M1-intro/L4-ag-dev-workflow'
      },
      {
        id: 'ethereum-provider-api',
        type: LESSON_TYPE.TEXT,
        title: 'Ethereum Provider API',
        slug: 'M7-web3-frontend/L1-ethereum-provider-api'
      },
      {
        id: 'demystifying-connect-wallet',
        type: LESSON_TYPE.TEXT,
        title: 'Demystifying Connect Wallet',
        slug: 'M7-web3-frontend/L2-demystifying-connect-wallet'
      }
    ]
  },
  {
    id: 'development-tools-and-frameworks',
    title: 'Development Tools and Frameworks',
    slug: 'S04-developer-tooling',
    lessons: [
      {
        id: 'web-3-javascript-libraries-web3js-and-ethersjs',
        type: LESSON_TYPE.TEXT,
        title: 'Web 3 Javascript Libraries (Web3.js and ethers.js)',
        slug: 'M2-web3-libraries/L1-intro-web3-ethers'
      },
      {
        id: 'connecting-to-a-smart-contract-using-web3js',
        type: LESSON_TYPE.TEXT,
        title: 'Connecting to a Smart Contract Using Web3.js',
        slug: 'M2-web3-libraries/L2-web3-connect-to-contract'
      },
      {
        id: 'introduction-to-infura',
        type: LESSON_TYPE.TEXT,
        title: 'Introduction to Infura',
        slug: 'M3-infura/L1'
      },
      {
        id: 'using-infura-to-access-ethereum-archive-data',
        type: LESSON_TYPE.TEXT,
        title: 'Using Infura to Access Ethereum Archive Data',
        slug: 'M3-infura/L2'
      },
      {
        id: 'tdd-and-truffle-tests',
        type: LESSON_TYPE.TEXT,
        title: 'TDD and Truffle Tests',
        slug: 'M4-truffle-deep-dive/L1-truffle-tests'
      },
      {
        id: 'truffle-debugger-configuration-mainnet-forking',
        type: LESSON_TYPE.TEXT,
        title: 'Truffle Debugger, Configuration, Mainnet Forking',
        slug: 'M4-truffle-deep-dive/L2-debug-config-forking'
      },
      {
        id: 'other-development-tools-hardhat-scaffoldeth-brownie',
        type: LESSON_TYPE.TEXT,
        title: 'Other Development Tools: Hardhat, Scaffold-Eth, Brownie',
        slug: 'M5-other-dev-tools'
      }
    ]
  },
  {
    id: 'second-order-effects',
    title: 'Second-Order Effects',
    slug: '',
    lessons: [
      {
        id: 'introduction-to-second-order-effects',
        type: LESSON_TYPE.TEXT,
        title: 'Introduction to "Second-Order Effects"',
        slug: 'S05a-defi/M0-concepts'
      },
      {
        id: 'what-is-defi',
        type: LESSON_TYPE.TEXT,
        title: 'What is DeFi?',
        slug: 'S05a-defi/M1-intro/L1-what-is-defi'
      },
      {
        id: 'key-terms',
        type: LESSON_TYPE.TEXT,
        title: 'Key Terms',
        slug: 'S05a-defi/M1-intro/L2-key-terms'
      },
      {
        id: 'what-are-stablecoins',
        type: LESSON_TYPE.TEXT,
        title: 'What are Stablecoins?',
        slug: 'S05a-defi/M2-stablecoins/L1'
      },
      {
        id: 'nfts-more-than-solidity-code',
        type: LESSON_TYPE.TEXT,
        title: 'NFTs: More than Solidity Code',
        slug: 'S05a-defi/M3-nfts/L1'
      },
      {
        id: 'what-are-wrapped-tokens',
        type: LESSON_TYPE.TEXT,
        title: 'What are Wrapped Tokens?',
        slug: 'S05a-defi/M4-wrapped/L1'
      },
      {
        id: 'what-are-dexes',
        type: LESSON_TYPE.TEXT,
        title: 'What are DEXes?',
        slug: 'S05a-defi/M5a-dexes/L1'
      },
      {
        id: 'what-are-amms',
        type: LESSON_TYPE.TEXT,
        title: 'What are AMMs?',
        slug: 'S05a-defi/M5b-amms/L1'
      },
      {
        id: 'what-are-rfqs',
        type: LESSON_TYPE.TEXT,
        title: 'What are RFQs?',
        slug: 'S05a-defi/M5c-rfqs/L1'
      },
      {
        id: 'oracles-revisited',
        type: LESSON_TYPE.TEXT,
        title: 'Oracles Revisited',
        slug: 'S05a-defi/M6-oracles/L1'
      },
      {
        id: 'what-are-defi-lending-markets',
        type: LESSON_TYPE.TEXT,
        title: 'What are DeFi Lending Markets?',
        slug: 'S05a-defi/M7-defi-lending/L1'
      },
      {
        id: 'what-are-governance-tokens',
        type: LESSON_TYPE.TEXT,
        title: 'What are Governance Tokens?',
        slug: 'S05a-defi/M8-governance/L1'
      },
      {
        id: 'introduction-to-metamask-swaps',
        type: LESSON_TYPE.TEXT,
        title: 'Introduction to MetaMask Swaps',
        slug: 'S05a-defi/M9-swaps/L1'
      },
      {
        id: 'what-are-daos',
        type: LESSON_TYPE.TEXT,
        title: 'What are DAOs?',
        slug: 'S06-daos/M1-understand'
      },
      {
        id: 'build-a-dao',
        type: LESSON_TYPE.TEXT,
        title: 'Build a DAO',
        slug: 'S06-daos/M2-build'
      },
      {
        id: 'manage-a-dao',
        type: LESSON_TYPE.TEXT,
        title: 'Manage a DAO',
        slug: 'S06-daos/M3-manage'
      }
    ]
  },
  {
    id: 'additional-topics',
    title: 'Additional Topics',
    slug: 'S07-additional-topics',
    lessons: [
      {
        id: 'introduction-to-ipfs',
        type: LESSON_TYPE.VIDEO,
        title: 'Introduction to IPFS',
        url: 'https://drive.google.com/file/d/1VakSHti449o1KfktRtJGT-kWiiYE_5_1/preview?usp=sharing'
      },
      {
        id: 'filecoin',
        type: LESSON_TYPE.TEXT,
        title: 'Filecoin',
        slug: 'L2-filecoin'
      },
      {
        id: 'ethereum-name-service',
        type: LESSON_TYPE.VIDEO,
        title: 'Ethereum Name Service',
        url: 'https://drive.google.com/file/d/1LsNVRSED4aYTUx7IHVNfJJVqS_qWrpWb/preview?usp=sharing'
      },
      {
        id: 'the-graph',
        type: LESSON_TYPE.TEXT,
        title: 'The Graph',
        slug: 'L3-the-graph'
      },
      {
        id: 'zeroknowledge-proofs',
        type: LESSON_TYPE.TEXT,
        title: 'Zero-Knowledge Proofs',
        slug: 'L4-zkp'
      }
    ]
  },
  {
    id: 'layer-2-scalability-solutions',
    title: 'Layer 2 (L2) or Scalability Solutions',
    slug: 'S08-scalability',
    lessons: [
      {
        id: 'overview-of-l2-solutions',
        type: LESSON_TYPE.TEXT,
        title: 'Overview of L2 Solutions',
        slug: 'M1-intro/L1-overview'
      },
      {
        id: 'types-of-l2-solutions',
        type: LESSON_TYPE.TEXT,
        title: 'Types of L2 Solutions',
        slug: 'M2-types/L1'
      },
      {
        id: 'rubric-for-evaluating-l2-solutions',
        type: LESSON_TYPE.TEXT,
        title: 'Rubric for Evaluating L2 Solutions',
        slug: 'M3-rubric/L1'
      },
      {
        id: 'l2-examples-optimism',
        type: LESSON_TYPE.TEXT,
        title: 'L2 Examples: Optimism',
        slug: 'M4-examples/L1-optimism'
      },
      {
        id: 'l2-examples-arbitrum',
        type: LESSON_TYPE.TEXT,
        title: 'L2 Examples: Arbitrum',
        slug: 'M4-examples/L2-arbitrum'
      },
      {
        id: 'crosschain-and-interoperability',
        type: LESSON_TYPE.TEXT,
        title: 'Crosschain and Interoperability',
        slug: 'M5-crosschain/L1'
      }
    ]
  }
]
