import React from 'react'

import { HashRouter } from 'react-router-dom'
import { useLocalStorage } from 'react-use'

import Routes from './components/Routes'
import { Loader } from './components/atoms/Loader'
import ScrollToTop from './components/templates/ScrollToTop'
import GlobalStyle from './styles/GlobalStyle'

const PasswordForm = React.lazy(() => import('./components/pages/PasswordForm'))

const App = () => {
  const [passwordConfirmed, setPasswordConfirmed] = useLocalStorage(
    '__password_confirmed__'
  )

  if (
    Boolean(process.env.REACT_APP_PASSWORD_ENABLED) &&
    passwordConfirmed !== process.env.REACT_APP_PASSWORD
  ) {
    return (
      <>
        <GlobalStyle />
        <React.Suspense fallback={<Loader />}>
          <PasswordForm
            onSuccess={() => {
              setPasswordConfirmed(process.env.REACT_APP_PASSWORD)
            }}
          />
        </React.Suspense>
      </>
    )
  }

  return (
    <>
      <GlobalStyle />
      <HashRouter>
        <ScrollToTop>
          <Routes />
        </ScrollToTop>
      </HashRouter>
    </>
  )
}

export default App
