import Avvvatars from 'avvvatars-react'
import PropTypes from 'prop-types'

import { AvatarSizes, Wrapper } from './Avatar.style'

export default function Avatar({
  address,
  avatar,
  size = AvatarSizes.M,
  ...props
}) {
  return (
    <Wrapper {...props} size={size} data-testid='avatar'>
      {avatar ? (
        <img src={avatar} alt='ENS Avatar' />
      ) : (
        <Avvvatars value={address} style='shape' size={size} />
      )}
    </Wrapper>
  )
}

Avatar.propTypes = {
  address: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  size: PropTypes.number
}
