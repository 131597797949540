import { Navigate, Outlet } from 'react-router-dom'

import useSession from '../hooks/useSession'

const PrivateRoutes = () => {
  const { session } = useSession()
  return session ? <Outlet /> : <Navigate to='/' />
}

export default PrivateRoutes
