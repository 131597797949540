import { rest } from 'msw'

import { baseURL } from '../../api/http-client'
import { MODULES } from '../../constants/Modules'
import { hash } from '../../utils/index'

const lessons = MODULES.flatMap((module, i) => {
  if (i === 0) {
    return module.lessons.map((lesson) => ({
      moduleId: module.id,
      lessonId: lesson.id
    }))
  }
  if (i === 1) {
    return module.lessons
      .filter((_, j) => j <= 2)
      .map((lesson) => ({
        moduleId: module.id,
        lessonId: lesson.id
      }))
  }
}).filter(Boolean)

const getCompletedLessonsByUserHandler = rest.get(
  `${baseURL}/completedlessons/:address`,
  async (req, res, ctx) => {
    return res(
      ctx.json(
        lessons.map((lesson) => {
          return {
            lessonId: hash(lesson.lessonId),
            moduleId: hash(lesson.moduleId)
          }
        })
      )
    )
  }
)

const postCompleteLessonHandler = rest.post(
  `${baseURL}/completelesson`,
  async (req, res, ctx) => {
    return res(ctx.status(201))
  }
)

export const handlers = [
  getCompletedLessonsByUserHandler,
  postCompleteLessonHandler
]
