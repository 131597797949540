import styled from 'styled-components'

import { DEFAULT_COLOR_PALETTE } from '../../../styles/ColorPalette'

export const IconStyle = styled.div`
  color: ${({ color }) => {
    if (color.startsWith('#')) {
      return color
    }
    return DEFAULT_COLOR_PALETTE.palette[color]?.primary
  }};
  display: inline-block;
  svg {
    display: block;
  }
`
