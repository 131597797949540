import React from 'react'

import { Overlay, Wrap } from './Loader.style'

import loaderSVG from '../../../assets/svg/loader.svg'

export const LoaderWithOverlay = () => {
  return (
    <Overlay>
      <Wrap>
        <img src={loaderSVG} alt='Loader' />
      </Wrap>
    </Overlay>
  )
}

export const Loader = () => {
  return (
    <Wrap>
      <img src={loaderSVG} alt='Loader' />
    </Wrap>
  )
}
