import { rest } from 'msw'

import { baseURL } from '../../api/http-client'
import answers from '../answers'
import correctAnswers from '../correct-answers'

const getAnswersHandler = rest.get(
  `${baseURL}/quizanswers/:lessonId/:questionId`,
  async (req, res, ctx) => {
    const { lessonId, questionId } = req.params
    const questionAnswers = answers.filter(
      (answer) =>
        answer.lessonId === lessonId && answer.questionId === questionId
    )
    if (!questionAnswers.length) {
      return res(ctx.status(404))
    }
    return res(ctx.json(questionAnswers))
  }
)

export const postAnswersHandler = rest.post(
  `${baseURL}/quizuseranswer`,
  async (req, res, ctx) => {
    return res(ctx.status(201), ctx.json({ count: 1 }))
  }
)

export const getSuccessfulQuizResultHandler = rest.get(
  `${baseURL}/quizuserresults/:lessonId/:address`,
  async (req, res, ctx) => {
    const { lessonId } = req.params
    // success
    return res(
      ctx.json({
        userPoints: answers.length,
        totalPoints: answers.length,
        answers: answers
          .filter((answer) => answer.lessonId === lessonId)
          .map((a) => {
            const correct = correctAnswers.find((c) => c.id === a.id)
            return {
              ...a,
              correct: Boolean(correct),
              label: a.title,
              description: correct?.description
            }
          })
          .map((answer) => ({
            ...answer,
            userAnswers: answer.correct ? [{ id: 'answer-id' }] : undefined
          }))
      })
    )
  }
)

export const getFailureQuizResultHandler = rest.get(
  `${baseURL}/quizuserresults/:lessonId/:address`,
  async (req, res, ctx) => {
    return res(
      ctx.json({
        correct: 2,
        total: 10,
        answers: []
      })
    )
  }
)

export const handlers = [
  getAnswersHandler,
  postAnswersHandler,
  getSuccessfulQuizResultHandler
  // getFailureQuizResultHandler
]
