import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { DEFAULT_COLOR_PALETTE } from '../../../styles/ColorPalette'
import { SIZE } from '../../../styles/Size'

export const Container = styled.div`
  background-color: #282828;
  display: flex;
  cursor: default;
  flex-direction: row;
  border-radius: 25px;
  position: relative;
  gap: 0.5em;
  align-items: center;
  padding: 8px;
  > label {
    display: none;
  }
  > p {
    padding-bottom: 3px;
    padding-right: 1em;
  }

  @media screen and (max-width: ${SIZE.mobile}) {
    background-color: ${DEFAULT_COLOR_PALETTE.grayScales.background.disabled};
    height: 85px;
    padding: 20px;
    box-sizing: border-box;
    [name='avatar'] svg,
    img {
      height: 44px;
      width: 44px;
    }
  }
`

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  label {
    display: none;
    font-weight: 700;
    size: 11px;
    line-height: 1.5;
    color: ${DEFAULT_COLOR_PALETTE.grayScales.text.primary};
  }

  @media screen and (max-width: ${SIZE.mobile}) {
    label {
      display: block;
    }
    p {
      font-weight: 300;
      font-size: 8px;
      line-height: 1.5;
      color: ${DEFAULT_COLOR_PALETTE.palette.purple.primary};
    }

    &::after {
      content: '>';
      position: absolute;
      right: 20px;
      font-size: 32px;
      line-height: 40px;
      color: ${DEFAULT_COLOR_PALETTE.grayScales.text.primary};
    }
  }
`
