import React from 'react'

import PropTypes from 'prop-types'

import { AnchorStyled, ButtonStyled } from './Button.style'

import { VARIANT_COLOR_PALETTE_KEYS } from '../../../styles/ColorPalette'
import { ICONS } from '../../../styles/IconLibrary'
import Icon from '../Icon'

const Button = ({
  icon,
  iconPos = 'left',
  label,
  handleClick,
  variant,
  colorscheme = 'default',
  isDisabled,
  role = 'button',
  url,
  ...props
}) => {
  const iconProps = {}
  if (variant === 'primary') {
    if (colorscheme === 'default') {
      iconProps.color = 'white'
    } else {
      iconProps.color = 'default'
    }
  }
  if (variant === 'secondary') {
    iconProps.color = colorscheme
  }
  if (variant === 'tertiary') {
    if (colorscheme === 'default') {
      iconProps.color = 'default'
    } else {
      iconProps.color = 'white'
    }
  }
  return role === 'button' ? (
    <ButtonStyled
      role='button'
      {...props}
      variant={variant}
      onClick={handleClick}
      disabled={isDisabled}
      colorscheme={colorscheme}
      whileTap={!isDisabled && { opacity: 0.4 }}
      whileHover={!isDisabled && { opacity: 0.7 }}
    >
      {ICONS[icon] && iconPos === 'left' && <Icon name={icon} {...iconProps} />}
      {label}
      {ICONS[icon] && iconPos === 'right' && (
        <Icon name={icon} {...iconProps} />
      )}
    </ButtonStyled>
  ) : (
    <AnchorStyled
      {...props}
      variant={variant}
      href={url}
      rel='noopener noreferrer'
      target='_blank'
      disabled={isDisabled}
      colorscheme={colorscheme}
      whileTap={!isDisabled && { opacity: 0.4 }}
      whileHover={!isDisabled && { opacity: 0.7 }}
    >
      {ICONS[icon] && <Icon name={icon} />}
      {label}
    </AnchorStyled>
  )
}

export default Button

Button.defaultProps = {
  url: null,
  handleClick: null,
  colorscheme: 'default'
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  role: PropTypes.oneOf(['button', 'link']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', null]),
  handleClick: PropTypes.func,
  url: PropTypes.string,
  // Do the props thing here
  icon: PropTypes.oneOf(Object.keys(ICONS)),
  iconPos: PropTypes.oneOf(['left', 'right']),
  isDisabled: PropTypes.bool,
  colorscheme: PropTypes.oneOf(VARIANT_COLOR_PALETTE_KEYS)
}
