import { rest } from 'msw'

import { baseURL } from '../../api/http-client'
import { MODULES } from '../../constants/Modules'
import { hash } from '../../utils/index'

const postCompleteModuleHandler = rest.post(
  `${baseURL}/submitmodule`,
  async (req, res, ctx) => {
    const transactionHash =
      parseInt(process.env.REACT_APP_CHAIN_ID, 10) === 100
        ? '0x6a082508134350480640f61d37b5997a5dcf9a30c5f76fd3b054cabb396369d6'
        : '0x11187e4dba0383e7df6c8d86d04dd533bd86f37b4221d31b2ce826766693a4d8'
    return res(ctx.status(201), ctx.json({ transactionHash }))
    // return res(
    // ctx.status(500),
    // ctx.json({
    //   reason: 'Tx failed with reason'
    // })
    // )
  }
)

const getCompletedModulesByUsers = rest.get(
  `${baseURL}/inprogressmodules`,
  (req, res, ctx) => {
    const completedByUsers = MODULES.map((module) => {
      return {
        moduleId: hash(module.id),
        count: 10
      }
    })
    return res(ctx.json(completedByUsers))
  }
)

export const handlers = [postCompleteModuleHandler, getCompletedModulesByUsers]
