import React, { useEffect } from 'react'

import { AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'

import { Banner, CloseButton, Container, Overlay, PALETTE } from './Modal.style'

import { VARIANT_COLOR_PALETTE } from '../../../styles/ColorPalette'
import { dropIn } from '../../../styles/FramerVariants'
import Icon from '../../atoms/Icon'
import Portal from '../../atoms/Portal'

const Modal = ({ children, isOpen, handleClose, variant = 'success' }) => {
  // close modal with `Escape` key
  useEffect(() => {
    const closeOnEscapeKey = (e) =>
      Boolean(handleClose) && e.key === 'Escape' ? handleClose() : null
    document.body.addEventListener('keydown', closeOnEscapeKey)
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey)
    }
  }, [handleClose])

  return (
    <Portal wrapperId='portal'>
      <AnimatePresence>
        {isOpen ? (
          <Overlay>
            <Container
              key='dialog'
              animate='visible'
              exit='exit'
              initial='hidden'
              variants={dropIn}
            >
              <Banner variant={variant}>
                <Icon
                  name='cube'
                  variant={variant}
                  width={128}
                  height={128}
                  color={PALETTE[variant]?.first}
                />
              </Banner>
              {Boolean(handleClose) && (
                <CloseButton handleClick={handleClose} icon='close' />
              )}
              {children}
            </Container>
          </Overlay>
        ) : null}
      </AnimatePresence>
    </Portal>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  variant: PropTypes.oneOf(Object.keys(VARIANT_COLOR_PALETTE))
}

export default Modal
