import { motion } from 'framer-motion'
import styled from 'styled-components'

import FontFamily from '../../../constants/FontFamily'
import { buttonStyles } from '../../../styles/Shared'

export const Anchor = styled(motion.a)`
  background-color: transparent;
  border: none;
  border-radius: 25px;
  width: fit-content;
  font-family: ${FontFamily.FraktionSansBold}, sans-serif;
  cursor: pointer;
  display: flex;
  user-select: none;
  box-sizing: border-box;
  flex-direction: row;
  align-items: self-end;
  justify-content: center;
  gap: 0.5em;
  border-radius: 50px;
  vertical-align: top;
  padding: 0.6em 1em 0.65em;
  line-height: 1.2;
  text-decoration: none;
  img {
    height: 1.1em;
    width: 1.1em;
  }
  ${buttonStyles}
`
