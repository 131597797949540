import PropTypes from 'prop-types'

import Anchor from '../atoms/Anchor'
import Button from '../atoms/Button'
import Modal from '../organisms/Modal'

const TransactionSucceeded = ({
  isOpen,
  handleClose,
  message = 'Transaction has been correctly mined',
  links
}) => {
  let anchors
  if (links && links.length > 0) {
    anchors = links.map((link, index) => {
      const name =
        index === 0
          ? `Check transaction on ${process.env.REACT_APP_CHAIN_NAME}`
          : 'Click to view minted NFT on Marketplace'
      return <Anchor key={index} url={link} name={name} external={true} />
    })
  }

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} variant='blue'>
      <h3>Transaction Succeeded</h3>
      <p>{message}</p>
      {anchors && anchors}
      <Button
        variant='primary'
        colorscheme='blue'
        label='Close'
        handleClick={handleClose}
      />
    </Modal>
  )
}

TransactionSucceeded.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  message: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.string)
}

export default TransactionSucceeded
