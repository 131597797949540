import styled from 'styled-components'

// Recycle this or turn into its own component
export const Wrapper = styled.a`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  img,
  svg {
    user-select: none;
  }
`
