import React, { useState, useEffect, forwardRef } from 'react'

import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'

import {
  Brand,
  Burger,
  CloseButton,
  EXPANDED_CLASSNAME,
  Logo,
  Navbar,
  NavbarMenu,
  PreventScrolling
} from './Header.style'

import rubiks from '../../../assets/brand/rubiks-transparent.svg'
import { ReactComponent as BurgerIcon } from '../../../assets/svg/burger.svg'
import { ReactComponent as CloseIcon } from '../../../assets/svg/cross-circle.svg'
import Icon from '../../atoms/Icon'
import NavLink from '../../atoms/NavLink'
import AuthStatus from '../../molecules/AuthStatus'

const links = [
  {
    desktop: true,
    path: '/about',
    label: 'About'
  },
  {
    desktop: false,
    path: '',
    label: 'Decentralized Mechanics'
  },
  {
    desktop: false,
    path: '/course',
    label: 'Our Course'
  },
  {
    desktop: true,
    path: 'https://discord.com',
    label: 'Discord',
    icon: <Icon name='discord' role='img' />
  },
  {
    desktop: true,
    path: 'https://twitter.com',
    label: 'Twitter',
    icon: <Icon name='twitter' role='img' />
  },
  {
    desktop: false,
    path: '',
    label: 'Contribute'
  },
  {
    desktop: false,
    path: '',
    label: 'Support',
    icon: <Icon name='alert circle' role='img' />
  }
]

const Header = forwardRef(({ style }, ref) => {
  const location = useLocation()
  const [isMobileExpanded, setMobileExpanded] = useState(false)

  useEffect(() => {
    document
      .getElementById('root')
      ?.classList[isMobileExpanded ? 'add' : 'remove'](EXPANDED_CLASSNAME)
    return () => {
      document.getElementById('root')?.classList.remove(EXPANDED_CLASSNAME)
    }
  }, [isMobileExpanded])

  return (
    <>
      <PreventScrolling />
      <Navbar
        ref={ref}
        data-testid='header'
        onClick={() => {
          // Catch all click event and close the navbar
          setMobileExpanded(false)
        }}
        style={style}
      >
        <Brand data-testid='brand'>
          <Link to={'/'}>
            <Logo src={rubiks} alt='Education DAO' />
          </Link>
        </Brand>
        <Burger
          data-testid='burger'
          onClick={(event) => {
            // I need to stop propagation otherwise Navbar onClick handler will close
            // immediately
            event.stopPropagation()
            setMobileExpanded(true)
          }}
        >
          <BurgerIcon />
        </Burger>
        <NavbarMenu
          expanded={isMobileExpanded}
          aria-expanded={isMobileExpanded}
        >
          <CloseButton onClick={() => setMobileExpanded(false)}>
            <CloseIcon />
          </CloseButton>
          <ul>
            {links.map(({ path, label, icon, desktop }) => (
              <NavLink
                key={`${path}_${label}`}
                desktop={desktop}
                path={path}
                disabled={path === ''}
                active={location.pathname === path}
              >
                {icon}
                <label>{label}</label>
              </NavLink>
            ))}
            <AuthStatus />
          </ul>
        </NavbarMenu>
      </Navbar>
    </>
  )
})
Header.displayName = 'Header'

Header.propTypes = {
  style: PropTypes.object
}

export default Header
