import React from 'react'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Container } from './NavLink.style'

const NavLink = ({
  path,
  active = false,
  desktop = true,
  children,
  disabled = false
}) => {
  const external = path.startsWith('http')

  // Prevent click event if element is disabled
  function stopDisabled(event) {
    if (disabled) {
      event.preventDefault()
      event.stopPropagation()
    }
  }
  return (
    <Container active={active} disabled={disabled} desktop={desktop}>
      {external ? (
        <a href={path} target='_blank' rel='noreferrer' onClick={stopDisabled}>
          {children}
        </a>
      ) : (
        <Link to={path} onClick={stopDisabled}>
          {children}
        </Link>
      )}
    </Container>
  )
}

NavLink.propTypes = {
  path: PropTypes.string.isRequired,
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  desktop: PropTypes.bool,
  disabled: PropTypes.bool
}

export default NavLink
