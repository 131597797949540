import { useEffect, useRef, useState } from 'react'

import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import useNotification, { NotificationTypes } from '../../hooks/useNotification'
import { isMobile } from '../../utils'
import Wrapper from '../atoms/Wrapper/Wrapper'
import Footer from '../organisms/Footer'
import Header from '../organisms/Header'

const Main = styled.main`
  flex: 1 0 0;
  margin-bottom: 64px;
`

const Layout = () => {
  const [notifyMobile, setNotifyMobile] = useState(isMobile())
  const { notify } = useNotification()

  const headerRef = useRef()

  // Track user scroll in order to show navbar when user scroll up
  useEffect(() => {
    const root = document.getElementById('root')
    let lastScrollPosition = 0

    function trackScroll() {
      if (!headerRef.current) {
        return
      }
      let { height } = headerRef.current.getBoundingClientRect()

      // As I set `position: fixed` on the header, I need to fill the empty space
      root.style.paddingTop = `${height}px`
      const currentScrollPosition = root.scrollTop

      headerRef.current.style.position = 'fixed'
      headerRef.current.style.transition = 'top 250ms linear'
      headerRef.current.style.top =
        currentScrollPosition > lastScrollPosition ? `-${height}px` : '0'

      // I store lastScrollPosition inside `useEffect` scope. I can use an `useState` hoof
      // for that, but in that case I need to add `lastScrollPosition` as dependency of
      // `useEffect` hook and that force React to add and remove this listener on any scroll
      lastScrollPosition = currentScrollPosition
    }

    // I execute it once to update css styles
    trackScroll()
    root.addEventListener('scroll', trackScroll, false)

    // clean up function
    return () => {
      root.removeEventListener('scroll', trackScroll)
    }
  }, [])

  if (notifyMobile) {
    notify(NotificationTypes.SuggestMobile)
    setNotifyMobile(false)
  }

  return (
    <Wrapper>
      <Header ref={headerRef} />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </Wrapper>
  )
}

export default Layout
