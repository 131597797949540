import PropTypes from 'prop-types'

import { IconStyle } from './Icon.style'

import { ICONS } from '../../../styles/IconLibrary'

const Icon = ({ name, height = 18, width = 18, color = 'white', ...props }) => {
  const SvgIcon = ICONS[name]?.icon

  if (SvgIcon) {
    return (
      <IconStyle data-testid='icon-style' color={color} {...props}>
        <SvgIcon data-testid='icon' width={width} height={height} />
      </IconStyle>
    )
  }
  return null
}

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(ICONS)),
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string
}

export default Icon
