import { ethers } from 'ethers'

import { MODULES } from '../constants/Modules'

export const formatAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`
}

export function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime() //Timestamp
  var d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

/**
 * @param input the input string to be hashed
 * @returns keccak256 of the input
 */
export const hash = (input) => {
  return ethers.utils.keccak256(
    ethers.utils.defaultAbiCoder.encode(['string'], [input])
  )
}

/**
 * @param moduleHash the hash of the module's title
 * @returns {*} The title of the module corresponding to the received hash
 */
export const moduleTitleFromHash = (moduleHash) => {
  let foundModule = MODULES.find((module) => {
    if (hash(module.id) === moduleHash) return module
  })
  if (!foundModule) return
  return foundModule.title
}

export function formatPercentage(value) {
  return Math.floor(value * 100) + '%'
}

const mobileRegex =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i

export function isMobile() {
  return mobileRegex.test(navigator.userAgent)
}
