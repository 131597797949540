module.exports = {
  100: {
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: '0x64',
        rpcUrls: ['https://rpc.gnosischain.com'],
        chainName: 'Gnosis Chain',
        nativeCurrency: {
          name: 'xDAI',
          symbol: 'xDAI',
          decimals: 18
        },
        blockExplorerUrls: [
          'https://gnosisscan.io',
          'https://epor.io/tokens/SMART_CONTRACT/TOKEN_ID?network=xDai'
        ]
      }
    ]
  },
  5: {
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: '0x5',
        rpcUrls: [
          'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
        ],
        chainName: 'Goerli Test Network',
        nativeCurrency: {
          name: 'GoerliETH',
          symbol: 'GoerliETH'
          // decimals: 18
        },
        blockExplorerUrls: [
          'https://goerli.etherscan.io',
          'https://testnets.opensea.io/assets/goerli'
        ]
      }
    ]
  }
}
