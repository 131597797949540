import PropTypes from 'prop-types'

import { Container } from './Wrapper.style'

export default function Wrapper({ children }) {
  return <Container>{children}</Container>
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired
}
